/**
 * @author: Elena Giacomazzi, Leon Fruth, Franziska Mäckel
 * @date: 2021-03-30
 */
 
 .panel {
  padding: 1vw;
  width: 50vw;
  height: 38vw;
  border-radius: 5%;
  background-color: rgba(255, 255, 255, 0.7);
}

.panel > .grid-container {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: auto auto 0fr auto;
  grid-column-gap: 0.5vw;
  grid-row-gap: 1.5vw;
  grid-template-areas:
    
    '. minuendA minuendB minuendC minuendD minuendE . .'
    '. correction0 correction1 correction2 correction3 correction4 . .'
    'minus subtrahendA subtrahendB subtrahendC subtrahendD subtrahendE . .'
    'line line line line line line . .'
    '. resultA resultB resultC resultD resultE check refresh'
    'error error error error error error error error';
}

.line {
  border-top: 0.5vw solid var(--primary-lila);
  grid-area: line;
}

.minus {
  font-size: 5vw;
  text-align: center;
  grid-area: minus;
}

.panelControls {
  background-color: var(--primary-lila);
  border: none;
  border-radius: 10%;
}

.correction0 {
  grid-area: correction0;
}

.correction1 {
  grid-area: correction1;
}

.correction2 {
  grid-area: correction2;
}

.correction3 {
  grid-area: correction3;
}

.correction4 {
  grid-area: correction4;
}

.subtrahend0 {
  grid-area: subtrahendA;
}

.subtrahend1 {
  grid-area: subtrahendB;
}
.subtrahend2 {
  grid-area: subtrahendC;
}
.subtrahend3 {
  grid-area: subtrahendD;
}
.subtrahend4 {
  grid-area: subtrahendE;
}
.minuend0 {
  grid-area: minuendA;
  width: 6vw;
  height: 6vw;
}
.minuend1 {
  grid-area: minuendB;
  width: 6vw;
  height: 6vw;
}
.minuend2 {
  grid-area: minuendC;
  width: 6vw;
  height: 6vw;
}
.minuend3 {
  grid-area: minuendD;
  width: 6vw;
  height: 6vw;
}
.minuend4 {
  grid-area: minuendE;
  width: 6vw;
  height: 6vw;
}
.result0 {
  grid-area: resultA;
}
.result1 {
  grid-area: resultB;
}
.result2 {
  grid-area: resultC;
}
.result3 {
  grid-area: resultD;
}
.result4 {
  grid-area: resultE;
}
.check {
  grid-area: check;
}
.refresh {
  grid-area: refresh;
}
.errorMessage {
  grid-area: error;
  font-family: Arial, Helvetica, sans-serif;
  color: var(--error-pink);
  font-size: 1.5vw;
  margin-top: 2vw;
}

.highlighted {
  background: var(--highlight) !important;
}

.analogy .panel {
  width: 25vw;
  height: 18vw;
  border-radius: 5%;
  background-color: rgba(255, 255, 255, 0.7);
  align-content: center;
}
/* add fourth column */
.analogy .panel > .grid-container {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: auto auto 0fr auto;
  grid-column-gap: 0.25vw;
  grid-row-gap: 0.9vw;
  grid-template-areas:
  '. minuendA minuendB minuendC minuendD minuendE . .'
  '. correction0 correction1 correction2 correction3 correction4 . .'
  
  'minus subtrahendA subtrahendB subtrahendC subtrahendD subtrahendE . .'
  'line line line line line line . .'
  '. resultA resultB resultC resultD resultE check refresh';
}
.analogy .line {
  border-top: 0.25vw solid var(--primary-lila);
  grid-area: line;
}

.analogy .minus {
  font-size: 2.5vw;
  text-align: center;
  grid-area: minus;
}

.analogy .panelControls {
  background-color: var(--primary-lila);
  border: none;
  border-radius: 10%;
}

.analogy .correction0 {
  grid-area: correction0;
}

.analogy .correction1 {
  grid-area: correction1;
}

.analogy .correction2 {
  grid-area: correction2;
}
.analogy .correction3 {
  grid-area: correction3;
}
.analogy .correction4 {
  grid-area: correction4;
}

.analogy .subtrahend0 {
  grid-area: subtrahendA;
}

.analogy .subtrahend1 {
  grid-area: subtrahendB;
}
.analogy .subtrahend2 {
  grid-area: subtrahendC;
}
.analogy .subtrahend3 {
  grid-area: subtrahendD;
}
.analogy .subtrahend4 {
  grid-area: subtrahendE;
}
.analogy .minuend0 {
  grid-area: minuendA;
  width: 3vw;
  height: 1vw;
}
.analogy .minuend1 {
  grid-area: minuendB;
  width: 3vw;
  height: 3vw;
}
.analogy .minuend2 {
  grid-area: minuendC;
  width: 3vw;
  height: 3vw;
}
.analogy .minuend3 {
  grid-area: minuendD;
  width: 3vw;
  height: 3vw;
}.analogy .minuend4 {
  grid-area: minuendE;
  width: 3vw;
  height: 3vw;
}
.analogy .result0 {
  grid-area: resultA;
}
.analogy .result1 {
  grid-area: resultB;
}
.analogy .result2 {
  grid-area: resultC;
}
.analogy .result3 {
  grid-area: resultD;
}
.analogy .result4 {
  grid-area: resultE;
}
