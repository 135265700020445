/**
 * @author: Elena Giacomazzi, Leon Fruth, Franziska Mäckel
 * @date: 2021-03-30
 */

 .clickableNumber {
  border: 0vw solid var(--primary-lila);
}



.X {
  position: relative;
  color: black;
  top: 0vw;
  left: 2.95vw;
  /*font-family: Arial, Helvetica, sans-serif;*/
  font-size: 5.5vw;
  /*opacity: 0.8;*/
  font-weight: 1000;
}
.analogy .clickableNumber {
  border: 0vw solid var(--primary-lila);
}

.analogy .X {
  position: relative;
  color: var(--primary-lila);
  top: 0vw;
  left: 1.5vw;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 3.1vw;
  opacity: 0.8;
}
