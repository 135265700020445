/**
 * @author: Elena Giacomazzi, Leon Fruth, Franziska Mäckel
 * @date: 2021-03-30
 */

:root {
  --primary-lila: #24305e;
  --light-blue: #a8d0e6;
  --button-grey: #e5e5e5;
  --error-pink: rgb(255, 0, 111);
  --font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica, Arial, sans-serif;
  --highlight: #f99a9b;
}
