/**
 * @author: Elena Giacomazzi, Leon Fruth, Franziska Mäckel
 * @date: 2021-03-30
 */

.ownEx {
  display: grid;
  grid-template-areas:
    'bubble subkraki'
    'bubble subkraki';
  grid-template-columns: 2fr 1fr;
  padding-top: 4vw;
}

.ownEx > .Subkraki {
  grid-area: subkraki;
  margin: auto;
}

.ownEx > .Subkraki > img {
  height: 30vw;
}
